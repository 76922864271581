<template>
  <v-card>
    <v-toolbar>
      <span class="headline">Add Fine</span>

      <v-spacer></v-spacer>
        <v-btn icon @click="createFine" :loading="waitForSave">
          <v-icon>save</v-icon>
        </v-btn>
  
        <v-btn @click="closeCard" icon>
          <v-icon color="red">close</v-icon>
        </v-btn>
      </v-toolbar>

    <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="12" md="12">
            <v-text-field label="Reason for Fine" v-model="item.reasonForFine" :error="requiredFieldsMessage && item.reasonForFine == null"  />
          </v-col>
          <v-col cols="10" md="10" sm="10">
            <v-text-field label="Location of Infringement" v-model="item.locationOfFine" :error="requiredFieldsMessage && item.locationOfFine == null" />
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-text-field label="Postal Code" v-model="item.postalCode"  />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6" md="6" sm="6">
            <v-text-field label="Issuer of Fine" v-model="item.fineIssuer"  />
          </v-col>
          <v-col cols="2" md="2" sm="2">
            <v-autocomplete
              ref="currencySelect"
              hide-no-data
              hide-selected
              label="Currency"
              :items="countries"
              item-text="currencyCode"
              item-value="id"
              :menu-props="{ closeOnContentClick: true }"
              :error="requiredFieldsMessage && item.selectedCurrency == null" 
              return-object
              v-model="item.selectedCurrency"
            >
              <template v-slot:selection="data">
                <v-avatar size="32" class="mr-2" v-if="data.item">
                  <v-img
                    v-if="data.item.iso2"
                    contain
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-avatar>
                <span v-if="data.item">
                  {{ data.item.currencyCode }}
                </span>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar>
                  <v-img
                    contain
                    v-if="data.item.iso2"
                    :src="`https://cdn.loglive.io/flags/4x3/${data.item.iso2.toLowerCase()}.svg`"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                  > {{  data.item.currencyCode }}
                </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="4" md="4" sm="4">
            <v-text-field label="Penalty Amount" min="0" v-model.number="item.penaltyAmount" @keypress="filter($event)" :error="requiredFieldsMessage && item.penaltyAmount == null" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" md="6" sm="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.dateOfFine"
                  :error="requiredFieldsMessage && item.dateOfFine == null"
                  label="Date of Infringement"
                  prepend-inner-icon="today"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.dateOfFine"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="1970-01-01"
                @change="save"
              ></v-date-picker>
            </v-menu>
          </v-col>
          
          <v-col cols="6" md="6" sm="6" >
            <v-container>
            <v-switch
            class="ml-0 pl-0"
            :label="item.isPaid ? 'Fine has been Paid' : 'Fine not Paid'"
            :error="item.isPaid != true"
            v-model="item.isPaid"
            color="success"
            >

            </v-switch>
          </v-container>
          </v-col>
        </v-row>
    </v-card-text>
    <v-snackbar :value="requiredFieldsMessage"
          :timeout="4000"
          color="primary"
        >
          <span style="font-size: large;"> Please fill in all required fields</span>
      </v-snackbar>


    <v-dialog max-width="400" v-model="areYouSure">
          <v-card height="150" >
            <v-toolbar> <v-subheader style="text-align: center">Are you sure you want to exit without saving? Your unsaved progress will be lost. </v-subheader></v-toolbar>
            <span class="d-flex justify-center mt-7">
              <v-btn @click="confirmation('yes')" class="mr-5"  >YES</v-btn>
            <v-btn @click="confirmation('no')" class="ml-5" color="primary">NO</v-btn>
            </span>
          </v-card>
        </v-dialog>

    <v-snackbar v-model="errorMessage"
          :timeout="4000"
          color="primary"
        >
          An error occurred while saving. Please try again
      </v-snackbar>
  </v-card>
</template>

<script>
export default {
  props: ["driver", "vehicle", "countries"],
    components: {
    },
  data: () => ({
    item: {},
    activePicker: null,
    menu: false,
    currencyId: null,
    currency: null,
    selectedCurrency: null,
    loadCircular: false,
    requiredFieldsMessage: false,
    areYouSure: false,
    errorMessage: false,
    waitForSave: false,
  }),

  watch: {
    item(val) {
      console.log(val)
    },

    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },

    filter: function(evt) {
      evt = (evt) ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();
      
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    closeCard () {
      this.$emit('close')
    },


    async createFine() {
      this.waitForSave = true
      let fine = {
      dateOfFine: this.item.dateOfFine,
      currency: this.item.selectedCurrency.currencyCode,
      currencyId: this.item.selectedCurrency.id,
      isPaid: this.item.isPaid,
      transportDriverId: this.driver && this.driver.id ? this.driver.id : null,
      transportVehicleId: this.vehicle.id ? this.vehicle.id : this.driver.transportUnit.transportVehicles[0].id ? this.driver.transportUnit.transportVehicles[0].id : null,
      reasonForFine: this.item.reasonForFine,
      locationOfFine: this.item.locationOfFine,
      postalCode: this.item.postalCode,
      fineIssuer: this.item.fineIssuer,
      penaltyAmount: this.item.penaltyAmount,
      }
      let result = await this.$API.createFine(fine)
      if(result) {
        this.waitForSave = false
        this.$emit('save', result)
        this.$emit('done')
      } else {
        this.errorMessage = true
      }
    }
  },
};
</script>

<style scoped>

</style>
